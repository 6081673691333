(function(){

    $(document).ready(function() {
       select2numberSize();
       preloaderInit();
       $(".flatpickr").flatpickr();
    //    carSmoothScroll();
        // YOUR UI SCRIPTS HERE
    });



    function preloaderInit() {
        $('body').addClass('loaded');
    }



    function select2numberSize() {
        var options = {
            select2Selector: '.select2',
            numberSelector: '.number',
            changedNumberSizeClass: '.big'
        };
        $(options.select2Selector).click(function () {
            console.log("dsa");
            $(options.numberSelector).addClass(options.changedNumberSizeClass);
        });
    }



})();
