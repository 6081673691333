function expandMenu() {
  $(".navbar").addClass("in");
  $(".burger-icon").addClass("open");
  $("body").addClass("frozen");
  $(".filter-mobile").addClass("active");
}
function collapseMenu(){
  $(".navbar").removeClass("in");
  $(".burger-icon").removeClass("open");
  $("body").removeClass("frozen");
  $(".filter-mobile").removeClass("active");
}


$(document).ready(function(){
  $("body").click(function(event) {
    var _target = event.target,
        $target = $(_target);


    if ($target.closest(".navbar").length !== 0) {
      console.log("clicked on the menu");
    }
    else if ($target.closest(".burger-icon").length !== 0) {
      // console.log("clicked on the burger");
      if ($(".navbar").hasClass("in")) {
        collapseMenu();
      }
      else {
        expandMenu();
      }

    }
    else if ($target.closest(".side-menu-trigger").length !== 0) {
      $(".menu-container").toggleClass("open");
      $(".form").toggleClass("out");
    }
    else {
      // console.log("clicked outside the menu");
      if ($(".navbar").hasClass("in")) {
        collapseMenu();
      }
    }

  });
});
